var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
//
import { TreeViewCollapseIcon, TreeViewExpandIcon, TreeViewEndIcon } from './CustomIcons';
// ----------------------------------------------------------------------
export default function TreeView(theme) {
    return {
        MuiTreeView: {
            defaultProps: {
                defaultCollapseIcon: _jsx(TreeViewCollapseIcon, { sx: { width: 20, height: 20 } }),
                defaultExpandIcon: _jsx(TreeViewExpandIcon, { sx: { width: 20, height: 20 } }),
                defaultEndIcon: _jsx(TreeViewEndIcon, { sx: { color: 'text.secondary', width: 20, height: 20 } }),
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                label: __assign({}, theme.typography.body2),
                iconContainer: {
                    width: 'auto',
                },
            },
        },
    };
}
